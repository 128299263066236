<template>


  <ul class="controls z-50 controls">
    <router-link  to="/pagetransitions3/fade"><li>Fade</li></router-link>
    <router-link to="/pagetransitions3/slide" > <li>Slide</li></router-link>
    <router-link to="/pagetransitions3/slideup"><li> SlideUp</li></router-link>
    <router-link to="/pagetransitions3/zoom"><li> Zoom</li></router-link>
    <router-link to="/pagetransitions3/flipx"><li> FlipX </li>   </router-link>
    <router-link to="/pagetransitions3/flipy"><li> FlipY</li></router-link>
  </ul>



  <router-view v-slot="{ Component }" class="absolute h-screen w-screen overflow-hidden justify-center items-center text-9xl text-white">
    <transition


        @enter="enter"
        @leave="leave"
        v-bind:css="false"
        appear

    >
      <component :is="Component" />
    </transition>
  </router-view>
</template>


<script>
import gsap from 'gsap'
var tl = gsap.timeline();


const fade = (el,done)=> {
  tl.fromTo(el, 1, {
    autoAlpha: 0,
    scale: 1.5,
  }, {
    autoAlpha: 1,
    scale: 1,
    transformOrigin: '50% 50%',
    ease: "power4",
    onComplete: done
  });
}
const fadeOut = (el,done)=> {
  tl.fromTo(el, 1, {
    autoAlpha: 1,
    scale: 1,
  }, {
    autoAlpha: 0,
    scale: 0.8,
    ease: "power4",
    onComplete: done
  });
}
const slide = (el,done) => {
  tl = gsap.timeline({
    onComplete: done
  });
  tl.set(el, {
    x: window.innerWidth * 1.5,
    scale: 0.8,
    transformOrigin: '50% 50%'
  })
  tl.to(el, 0.5, {
    x: 0,
    ease: "power4",
  });
  tl.to(el, 1, {
    scale: 1,
    ease: "power4",
  });
}
const slideOut = (el,done) => {
  tl.fromTo(el, 1, {
    autoAlpha: 1
  }, {
    autoAlpha: 0,
    ease: "power4",
    onComplete: done
  });
}
const slideUp = (el,done) => {
  tl = gsap.timeline({
    onComplete: done
  });
  tl.set(el, {
    y: window.innerWidth * 1.5,
    scale: 0.8,
    transformOrigin: '50% 50%'
  })
  tl.to(el, 0.5, {
    y: 0,
    ease: "power4",
  });
  tl.to(el, 1, {
    scale: 1,
    ease: "power4",
  });
}
const slideUpOut = (el,done) => {
  tl.to(el, 1, {
    y: window.innerHeight * -1.5,
    ease: "power4",
    onComplete: done
  });
}
const zoom = (el,done) => {
  tl = gsap.timeline({
    onComplete: done
  });
  tl.set(el, {
    autoAlpha: 0,
    scale: 2,
    transformOrigin: '50% 50%'
  })
  tl.to(el, 1, {
    autoAlpha: 1,
    scale: 1,
    ease: "power4",
  })
}
const zoomOut = (el,done) => {
  tl.to(el, 1, {
    scale: 0,
    ease: "power4",
    onComplete: done
  });
}
const flipX = (el,done) => {
  tl = gsap.timeline({
    onComplete: done
  });
  tl.set(el, {
    autoAlpha: 0,
    rotationX: 90,
    transformOrigin: '50% 50%'
  })
  tl.to(el, 1, {
    autoAlpha: 1,
    rotationX: 0,
    ease: "power4",
  })
}
const flipXOut = (el,done) => {
  tl.to(el, 1, {
    scale: 0,
    ease: "power4",
    onComplete: done
  });
}
const flipY = (el,done) => {
  tl = gsap.timeline({
    onComplete: done
  });
  tl.set(el, {
    autoAlpha: 0,
    rotationY: 90,
    transformOrigin: '50% 50%'
  })
  tl.to(el, 1, {
    autoAlpha: 1,
    rotationY: 0,
    ease: "power4",
  })
}
const flipYOut = (el,done) => {
  tl.to(el, 1, {
    scale: 0,
    ease: "power4",
    onComplete: done
  });
}
export default {
  name: 'App',
  data() {
    return {
      enter: null,
      leave:null,
    }
  },
  watch: {
    '$route'(to, from) {
      console.log(from)
      console.log(to)



      if (to.path === '/pagetransitions3/fade') {


        this.enter = fade


        this.leave = fadeOut



      } else if (to.path === '/pagetransitions3/slide') {
        this.enter = slide
        this.leave = slideOut


      }
      else if (to.path === '/pagetransitions3/slideup') {
        this.enter = slideUp
        this.leave = slideUpOut
      }
      else if (to.path === '/pagetransitions3/zoom') {
        this.enter = zoom
        this.leave = zoomOut
      }
      else if (to.path === '/pagetransitions3/flipx') {
        this.enter = flipX
        this.leave = flipXOut
      }
      else if (to.path === '/pagetransitions3/flipy') {
        this.enter = flipY
        this.leave = flipYOut
      }
    }},


  mounted() {

    document.body.classList.add('body--overflow');

  },

  unmounted(){


    document.body.classList.remove('body--overflow');

  }

}
</script>

<style scoped lang="scss">


.body--overflow {
  height: 100vh;
  overflow: hidden;
}

.controls {
  z-index: 1000;
  position: fixed;
  bottom: 40px;
  width: 100%;
  margin-top: 30px;
  text-align: center;
  padding: 0;
  li {
    opacity: 0.6;
    cursor: pointer;
    overflow: hidden;
    display: inline-block;
    height: 30px;
    margin: 0 10px;
    padding: 0 30px;
    border-radius: 10px;
    background: #505050;
    color:white;
    z-index: 100000;
    &.active {
      background: lighten(#505050, 40%);
    }
    a {
      width: 100%;
      color:white;
      text-decoration: none;
    }
  }
}

.page {
  height: 100vh;
  display: flex;
  h1 {
    color: #fff;
    margin: auto;
  }
}
#Fade {
  background-color: #ff8383;
}
#Slide {
  background-color: #82c8a0;
}
#Zoom {
  background-color: #ffa07a;
}
#FlipX {
  background-color: #c4d1cc;
}
#FlipY {
  background-color: #abc51c;
}
#SlideUp {
  background-color: #1d312b;
}

</style>